import React from "react";

export const MartinTozer = () => {
  return (
    <svg
      id="logoMT"
      width="1434"
      height="191"
      viewBox="0 0 1434 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* M */}
      <path
        d="M183.551 20.801C184.772 23.091 185.459 25.152 185.612 26.984C185.917 28.816 186.07 31.4877 186.07 34.999V162.094C186.07 172.628 183.169 178.811 177.368 180.643C174.467 181.559 170.803 182.017 166.376 182.017C161.949 182.017 158.437 181.635 155.842 180.872C153.247 180.109 151.262 179.193 149.888 178.124C148.514 177.055 147.445 175.529 146.682 173.544C145.919 170.949 145.537 166.979 145.537 161.636V84.921C141.72 89.6537 136.148 97.2107 128.82 107.592C121.492 117.821 116.836 124.233 114.851 126.828C112.866 129.423 111.492 131.255 110.729 132.324C109.966 133.24 108.057 134.614 105.004 136.446C102.103 138.125 98.8973 138.965 95.386 138.965C92.0273 138.965 88.8976 138.202 85.997 136.675C83.249 134.996 81.2643 133.393 80.043 131.866L78.211 129.347C75.1576 125.53 69.1273 117.439 60.12 105.073C51.1126 92.5543 46.2273 85.837 45.464 84.921V162.094C45.464 165.605 45.3113 168.277 45.006 170.109C44.8533 171.788 44.1663 173.697 42.945 175.834C40.655 179.956 34.701 182.017 25.083 182.017C15.7703 182.017 9.96897 179.956 7.67897 175.834C6.45764 173.697 5.69431 171.712 5.38897 169.88C5.23631 168.048 5.15997 165.3 5.15997 161.636V34.541C5.15997 31.0297 5.23631 28.4343 5.38897 26.755C5.69431 24.923 6.45764 22.862 7.67897 20.572C9.96897 16.6027 15.923 14.618 25.541 14.618C29.663 14.618 33.1743 15.1523 36.075 16.221C39.1283 17.137 41.113 18.1293 42.029 19.198L43.403 20.572L95.386 88.814C121.339 54.464 138.667 31.793 147.369 20.801C149.964 16.679 156.071 14.618 165.689 14.618C175.46 14.618 181.414 16.679 183.551 20.801Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* A */}
      <path
        d="M282.735 74.387C283.651 65.8377 289.681 61.563 300.826 61.563C306.78 61.563 311.207 62.021 314.108 62.937C317.008 63.853 318.993 65.456 320.062 67.746C321.283 70.036 321.97 72.097 322.123 73.929C322.428 75.6083 322.581 78.2037 322.581 81.715V161.865C322.581 165.376 322.428 168.048 322.123 169.88C321.97 171.559 321.359 173.544 320.291 175.834C318.153 179.803 312.886 181.788 304.49 181.788C296.246 181.788 290.673 180.796 287.773 178.811C285.025 176.674 283.422 173.62 282.964 169.651C277.315 177.742 267.85 181.788 254.568 181.788C241.438 181.788 229.225 175.758 217.928 163.697C206.63 151.636 200.982 137.667 200.982 121.79C200.982 105.76 206.63 91.791 217.928 79.883C229.378 67.8223 241.896 61.792 255.484 61.792C260.674 61.792 265.407 62.708 269.682 64.54C273.956 66.372 276.933 68.1277 278.613 69.807C280.292 71.3337 281.666 72.8603 282.735 74.387ZM241.057 122.019C241.057 127.057 243.041 131.637 247.011 135.759C250.98 139.728 255.789 141.713 261.438 141.713C267.086 141.713 271.743 139.652 275.407 135.53C279.223 131.408 281.132 126.904 281.132 122.019C281.132 116.981 279.3 112.325 275.636 108.05C272.124 103.775 267.315 101.638 261.209 101.638C255.255 101.638 250.369 103.775 246.553 108.05C242.889 112.325 241.057 116.981 241.057 122.019Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* R */}
      <path
        d="M422.815 62.937C425.715 63.853 428.082 65.456 429.914 67.746C431.898 69.8833 432.891 73.3947 432.891 78.28C432.891 83.1653 431.44 88.5087 428.54 94.31C425.639 100.111 421.441 103.012 415.945 103.012C413.197 103.012 410.525 102.401 407.93 101.18C405.487 99.9587 402.205 99.348 398.083 99.348C393.961 99.348 390.068 100.798 386.404 103.699C382.892 106.6 381.137 110.111 381.137 114.233V162.323C381.137 165.834 380.984 168.506 380.679 170.338C380.526 172.017 379.839 174.002 378.618 176.292C376.328 180.261 370.374 182.246 360.756 182.246C353.428 182.246 348.161 180.948 344.955 178.353C342.512 176.216 341.138 172.933 340.833 168.506C340.833 166.979 340.833 164.766 340.833 161.865V81.486C340.833 77.9747 340.909 75.3793 341.062 73.7C341.367 71.868 342.054 69.8833 343.123 67.746C345.26 63.624 351.214 61.563 360.985 61.563C370.145 61.563 375.87 63.3187 378.16 66.83C379.839 69.2727 380.679 71.7917 380.679 74.387C381.442 73.3183 382.511 72.0207 383.885 70.494C385.411 68.9673 388.541 66.9063 393.274 64.311C398.006 61.7157 402.128 60.418 405.64 60.418C409.304 60.418 412.357 60.647 414.8 61.105C417.243 61.4103 419.914 62.021 422.815 62.937Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* T */}
      <path
        d="M515.567 102.096L500.682 101.18V132.782C500.682 136.904 501.293 139.881 502.514 141.713C503.888 143.545 506.331 144.461 509.842 144.461C513.506 144.461 516.254 144.614 518.086 144.919C520.071 145.072 522.132 145.759 524.269 146.98C527.78 148.965 529.536 154.079 529.536 162.323C529.536 171.941 527.399 177.819 523.124 179.956C520.834 181.025 518.773 181.712 516.941 182.017C515.262 182.17 512.743 182.246 509.384 182.246C493.507 182.246 481.446 178.658 473.202 171.483C464.958 164.308 460.836 152.171 460.836 135.072V101.18C457.935 101.638 454.806 101.867 451.447 101.867C448.088 101.867 445.264 100.569 442.974 97.974C440.837 95.3787 439.768 90.9513 439.768 84.692C439.768 78.4327 440.15 73.929 440.913 71.181C441.676 68.2803 442.745 66.2193 444.119 64.998C446.562 63.0133 449.615 62.021 453.279 62.021L460.836 62.937V39.35C460.836 35.8387 460.912 33.2433 461.065 31.564C461.37 29.8847 462.134 28.0527 463.355 26.068C465.34 22.2513 471.217 20.343 480.988 20.343C491.369 20.343 497.552 23.1673 499.537 28.816C500.3 31.2587 500.682 35.1517 500.682 40.495V62.937C507.399 62.3263 512.437 62.021 515.796 62.021C519.307 62.021 521.903 62.1737 523.582 62.479C525.414 62.6317 527.475 63.3187 529.765 64.54C533.734 66.5247 535.719 72.4023 535.719 82.173C535.719 91.791 533.658 97.6687 529.536 99.806C527.246 100.875 525.185 101.562 523.353 101.867C521.674 102.02 519.078 102.096 515.567 102.096Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* I */}
      <path
        d="M548.364 81.715C548.364 78.2037 548.44 75.6083 548.593 73.929C548.898 72.097 549.585 70.036 550.654 67.746C552.791 63.7767 558.745 61.792 568.516 61.792C575.844 61.792 581.035 63.0897 584.088 65.685C586.683 67.8223 588.057 71.1047 588.21 75.532C588.363 77.0587 588.439 79.2723 588.439 82.173V162.323C588.439 165.834 588.286 168.506 587.981 170.338C587.828 172.017 587.141 174.002 585.92 176.292C583.935 180.261 578.058 182.246 568.287 182.246C558.669 182.246 552.791 180.185 550.654 176.063C549.585 173.773 548.898 171.788 548.593 170.109C548.44 168.43 548.364 165.758 548.364 162.094V81.715ZM562.791 50.342C562.791 50.342 560.501 50.8763 555.921 51.945C551.341 51.945 547.601 47.4413 544.7 38.434C543.479 34.77 542.868 31.6403 542.868 29.045C542.868 26.4497 543.326 24.465 544.242 23.091C545.769 21.1063 548.593 19.5033 552.715 18.282L590.5 6.603C594.469 5.53433 596.759 5 597.37 5C601.95 5 605.614 9.50367 608.362 18.511C609.583 22.175 610.194 25.3047 610.194 27.9C610.194 30.3427 609.736 32.251 608.82 33.625C607.293 35.6097 604.469 37.2127 600.347 38.434L562.791 50.342Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* N */}
      <path
        d="M673.829 61.563C689.096 61.563 701.767 67.4407 711.843 79.196C722.072 90.7987 727.186 104.997 727.186 121.79V162.094C727.186 165.605 727.034 168.277 726.728 170.109C726.576 171.788 725.965 173.697 724.896 175.834C722.759 179.956 716.805 182.017 707.034 182.017C696.195 182.017 689.936 179.193 688.256 173.544C687.34 170.949 686.882 167.056 686.882 161.865V121.561C686.882 115.302 685.05 110.416 681.386 106.905C677.875 103.394 673.066 101.638 666.959 101.638C661.005 101.638 656.12 103.47 652.303 107.134C648.639 110.798 646.807 115.607 646.807 121.561V162.094C646.807 165.605 646.655 168.277 646.349 170.109C646.197 171.788 645.51 173.697 644.288 175.834C642.304 179.956 636.426 182.017 626.655 182.017C617.037 182.017 611.16 179.956 609.022 175.834C607.954 173.544 607.267 171.559 606.961 169.88C606.809 168.048 606.732 165.376 606.732 161.865V80.799C606.732 77.4403 606.809 74.9213 606.961 73.242C607.267 71.41 608.03 69.4253 609.251 67.288C611.389 63.4713 617.266 61.563 626.884 61.563C636.197 61.563 641.922 63.3187 644.059 66.83C645.586 69.4253 646.349 72.555 646.349 76.219C646.96 75.1503 648.334 73.6237 650.471 71.639C652.609 69.6543 654.67 68.0513 656.654 66.83C661.845 63.3187 667.57 61.563 673.829 61.563Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* T */}
      <path
        d="M814.521 14.16H941.845C946.73 14.16 950.318 14.7707 952.608 15.992C954.898 17.0607 956.425 18.8163 957.188 21.259C957.952 23.7017 958.333 26.9077 958.333 30.877C958.333 34.8463 957.952 38.0523 957.188 40.495C956.425 42.785 955.127 44.388 953.295 45.304C950.547 46.678 946.654 47.365 941.616 47.365H897.877V162.552C897.877 166.063 897.724 168.659 897.419 170.338C897.266 172.017 896.579 174.002 895.358 176.292C894.289 178.429 892.305 179.956 889.404 180.872C886.504 181.788 882.687 182.246 877.954 182.246C873.221 182.246 869.405 181.788 866.504 180.872C863.756 179.956 861.772 178.429 860.55 176.292C859.482 174.002 858.794 172.017 858.489 170.338C858.336 168.506 858.26 165.834 858.26 162.323V47.365H814.292C809.407 47.365 805.819 46.8307 803.529 45.762C801.239 44.5407 799.712 42.7087 798.949 40.266C798.186 37.8233 797.804 34.6173 797.804 30.648C797.804 26.6787 798.186 23.549 798.949 21.259C799.712 18.8163 801.01 17.137 802.842 16.221C805.59 14.847 809.483 14.16 814.521 14.16Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* O */}
      <path
        d="M940.088 122.019C940.088 103.699 946.5 88.585 959.324 76.677C972.148 64.769 986.804 58.815 1003.29 58.815C1019.78 58.815 1034.36 64.769 1047.03 76.677C1059.7 88.4323 1066.04 103.47 1066.04 121.79C1066.04 134.461 1062.76 145.759 1056.19 155.682C1049.63 165.453 1041.61 172.704 1032.15 177.437C1022.83 182.017 1013.14 184.307 1003.06 184.307C992.987 184.307 983.217 181.864 973.751 176.979C964.286 171.941 956.271 164.613 949.706 154.995C943.294 145.224 940.088 134.232 940.088 122.019ZM987.949 138.507C992.835 142.171 997.796 144.003 1002.83 144.003C1007.87 144.003 1012.91 142.095 1017.95 138.278C1022.99 134.461 1025.51 128.813 1025.51 121.332C1025.51 113.851 1023.14 108.279 1018.41 104.615C1013.67 100.951 1008.56 99.119 1003.06 99.119C997.567 99.119 992.453 101.027 987.72 104.844C982.988 108.661 980.621 114.309 980.621 121.79C980.621 129.118 983.064 134.69 987.949 138.507Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* Z */}
      <path
        d="M1137.71 141.942H1176.87C1182.97 141.942 1187.32 142.705 1189.92 144.232C1192.67 145.759 1194.5 147.972 1195.41 150.873C1196.33 153.774 1196.79 157.59 1196.79 162.323C1196.79 167.056 1196.33 170.872 1195.41 173.773C1194.5 176.674 1192.9 178.735 1190.61 179.956C1188.32 181.025 1186.25 181.712 1184.42 182.017C1182.74 182.17 1180.15 182.246 1176.64 182.246H1093.97C1088.62 182.246 1083.97 180.261 1080 176.292C1076.03 172.323 1074.04 167.59 1074.04 162.094C1074.04 156.598 1076.03 151.942 1080 148.125C1083.97 144.156 1090.07 138.507 1098.32 131.179C1106.56 123.851 1114.04 117.286 1120.76 111.485C1127.48 105.531 1131.14 102.249 1131.75 101.638H1099.46C1090.76 101.638 1085.27 100.188 1082.98 97.287C1080.38 93.9283 1079.08 89.501 1079.08 84.005C1079.08 78.509 1079.46 74.4633 1080.23 71.868C1080.99 69.12 1081.91 67.1353 1082.98 65.914C1084.04 64.54 1085.57 63.4713 1087.56 62.708C1090.15 61.9447 1094.12 61.563 1099.46 61.563H1173.89C1179.23 61.563 1183.89 63.4713 1187.86 67.288C1191.83 71.1047 1193.81 75.9137 1193.81 81.715C1193.81 87.3637 1191.52 92.4017 1186.94 96.829C1182.36 101.256 1165.95 116.294 1137.71 141.942Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* E */}
      <path
        d="M1321.06 107.592C1321.06 116.905 1318.69 123.851 1313.96 128.431C1309.38 132.858 1304.27 135.072 1298.62 135.072H1244.34C1244.34 139.347 1246.86 142.934 1251.9 145.835C1256.94 148.736 1261.98 150.186 1267.01 150.186C1275.87 150.186 1282.82 149.27 1287.85 147.438L1290.37 146.522C1294.04 144.843 1297.09 144.003 1299.53 144.003C1304.42 144.003 1308.62 147.438 1312.13 154.308C1314.11 158.43 1315.1 161.941 1315.1 164.842C1315.1 178.429 1298.85 185.223 1266.33 185.223C1255.03 185.223 1244.95 183.315 1236.1 179.498C1227.4 175.529 1220.6 170.338 1215.72 163.926C1206.1 151.56 1201.29 137.744 1201.29 122.477C1201.29 103.241 1207.47 87.898 1219.84 76.448C1232.36 64.8453 1247.93 59.044 1266.56 59.044C1287.78 59.044 1303.35 66.5247 1313.27 81.486C1318.46 89.4247 1321.06 98.1267 1321.06 107.592ZM1272.74 114.233C1278.54 114.233 1281.44 111.79 1281.44 106.905C1281.44 103.394 1280.07 100.569 1277.32 98.432C1274.72 96.2947 1270.91 95.226 1265.87 95.226C1260.98 95.226 1256.1 97.287 1251.21 101.409C1246.33 105.378 1243.89 109.653 1243.89 114.233H1272.74Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
      {/* R */}
      <path
        d="M1417.98 62.937C1420.88 63.853 1423.25 65.456 1425.08 67.746C1427.06 69.8833 1428.06 73.3947 1428.06 78.28C1428.06 83.1653 1426.61 88.5087 1423.71 94.31C1420.81 100.111 1416.61 103.012 1411.11 103.012C1408.36 103.012 1405.69 102.401 1403.1 101.18C1400.65 99.9587 1397.37 99.348 1393.25 99.348C1389.13 99.348 1385.23 100.798 1381.57 103.699C1378.06 106.6 1376.3 110.111 1376.3 114.233V162.323C1376.3 165.834 1376.15 168.506 1375.84 170.338C1375.69 172.017 1375.01 174.002 1373.78 176.292C1371.49 180.261 1365.54 182.246 1355.92 182.246C1348.59 182.246 1343.33 180.948 1340.12 178.353C1337.68 176.216 1336.3 172.933 1336 168.506C1336 166.979 1336 164.766 1336 161.865V81.486C1336 77.9747 1336.08 75.3793 1336.23 73.7C1336.53 71.868 1337.22 69.8833 1338.29 67.746C1340.43 63.624 1346.38 61.563 1356.15 61.563C1365.31 61.563 1371.04 63.3187 1373.33 66.83C1375.01 69.2727 1375.84 71.7917 1375.84 74.387C1376.61 73.3183 1377.68 72.0207 1379.05 70.494C1380.58 68.9673 1383.71 66.9063 1388.44 64.311C1393.17 61.7157 1397.29 60.418 1400.81 60.418C1404.47 60.418 1407.52 60.647 1409.97 61.105C1412.41 61.4103 1415.08 62.021 1417.98 62.937Z"
        stroke="#F0F0F0"
        strokeWidth="10"
      />
    </svg>
  );
};
